import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const NotFoundWrapper = styled.div`
  display: flex;
  margin-top: 12rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 4.2rem;
    text-transform: uppercase;
  }

  a {
    margin: auto;
  }
`;

// markup
const NotFoundPage = () => {
  return (
    <NotFoundWrapper>
      <h1>404 Page not found</h1>
      <p>
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to='/' className='red-button'>
          Go home
        </Link>
        .
      </p>
    </NotFoundWrapper>
  );
};

export default NotFoundPage;
